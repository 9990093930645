import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link , useNavigate} from "react-router-dom";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import {post} from '../../helpers/api_helper'  

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

const PackageList = (props) => {
  useDefaultPropsWarningSuppression();

  //meta title
  document.title = props.t("Package List");

  const navigate = useNavigate();
  
  const [isNew, setIsNew] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [modal, setModal] = useState(false); 
  const [modalUpgrade, setModalUpgrade] = useState(false); 

  const [packages, setPackages] = useState();
  const [packageData, setPackageData] = useState();
  const [currentPackage, setCurrentPackage] = useState("");
  const [currentPackageId, setCurrentPackageId] = useState(0);
  const [currentPackageAmount, setCurrentPackageAmount] = useState(0); 
  const [upgrade_ids, setUpgrade_ids] = useState([]);
  
  const [total_reg, setTotal_reg] = useState(0);
  const [total_cash, setTotal_cash] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [packageTitle, setPackageTitle] = useState("");
  const [totalPay, setTotalPay] = useState(0);
  const [totalPay2, setTotalPay2] = useState(0);

  useEffect(() => { 
    packageList();  
    userProfile();
  }, []);  
 
  const packageList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/packageList", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
    } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data?.data){  
          let listdata = response.data.data.packages;  
          setPackageData(listdata); 
        } 
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/profile", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
      } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let userdata = response.data.data;
        setTotal_reg(userdata.total_reg);
        setTotal_cash(userdata.total_cash);
   
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const toggle = () => {
    if (modal) {
      setModal(false);
      setPackages(null);
    } else {
      setModal(true);
      setCurrentPackageAmount(0);
      setCurrentPackageId(0);
      setTotalPay(0);
    }
  };
 
  const toggleUpgrade = () => {
    if (modalUpgrade) {
      setModalUpgrade(false);
      setPackages(null);
    } else {
      setModalUpgrade(true);
      setCurrentPackageAmount(0);
      setCurrentPackageId(0);
      setTotalPay(0);
    }
  };
  const handlePackageClick = useCallback((arg) => {  
    setPackageId(arg.id); 
    setCurrentPackageAmount(arg.amount);  
    setTotalPay(arg.amount);  
    setPackageTitle("$"+arg.title);  
    setModal(true);
  }, [toggle]);

  const handlePackageUpgradeClick = useCallback((arg, ids) => {   
    setUpgrade_ids(arg.upgrade_ids); 
    setCurrentPackageId(arg.id); 
    setCurrentPackage("$"+arg.title);  
    setCurrentPackageAmount(arg.amount);  
    setModalUpgrade(true);
  }, [toggle]);
 
  const validation1 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {  
      pay_type: "total_reg", 
    },
    validationSchema: Yup.object({   
      pay_type: Yup.string().required(props.t("Please Select Pay Type"))
    }), 
    onSubmit: async (values) => { 
      try {  
          const obj = JSON.parse(localStorage.getItem("authUser"));    
          values.id =  packageId;
          values.token =  obj.token;
          const response = await post("/trade/purchasePackage", values); 

          if (response.data.code === 3002) {
              navigate('/logout'); 
          } 

          if (response.data.code !== 1) { 
            if(response.data.data?.message){
              let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
              Notify.failure(message); 
            } else { 
              Notify.failure(props.t(response.data.message)); 
            } 
          } else { 
            
            if(response.data.data?.result){
              Notify.success(props.t(response.data.data.result)); 
            } else { 
              Notify.success(props.t(response.data.message)); 
            } 


            setTotalPay(0);
            setModal(false);
            packageList(); 
            userProfile();
          }
      } catch (error) {  
          console.log(error);
          // Notify.failure(error.data.message); 
      }
    }, 
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      newpackageid: "", 
      pay_type: "total_reg", 
    },
    validationSchema: Yup.object({  
      newpackageid: Yup.string().required(props.t("Please Select New Package")),
      pay_type: Yup.string().required(props.t("Please Select Pay Type"))
    }), 
    onSubmit: async (values) => { 
      try {  
          const obj = JSON.parse(localStorage.getItem("authUser"));   
          values.oldpackageid =  currentPackageId;
          values.token =  obj.token;
          const response = await post("/trade/upgradePackage", values); 

          if (response.data.code === 3002) {
              navigate('/logout'); 
          } 

          if (response.data.code !== 1) { 
            if(response.data.data?.message){
              let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
              Notify.failure(message); 
            } else { 
              Notify.failure(props.t(response.data.message)); 
            } 
          } else { 
            
            if(response.data.data?.result){
              Notify.success(props.t(response.data.data.result)); 
            } else { 
              Notify.success(props.t(response.data.message)); 
            } 

            setTotalPay(0);
            setModalUpgrade(false);
            packageList(); 
            userProfile();
          }
      } catch (error) {  
          console.log(error);
          // Notify.failure(error.data.message); 
      }
    }, 
  });


  const isSubmitDisabled = () => {
    const { values } = validation; 
    if(values.pay_type === "total_reg"){  
      return parseFloat(totalPay) <= 0 || parseFloat(totalPay) > parseFloat(total_reg);
    } else {
      return parseFloat(totalPay) <= 0 || parseFloat(total_reg) < parseFloat(totalPay/2) || parseFloat(total_cash) < parseFloat(totalPay/2) ;
    }
  };

  const isPurchaseDisabled = () => {
    const { values } = validation1; 
    if(values.pay_type === "total_reg"){  
      return parseFloat(totalPay) <= 0 || parseFloat(totalPay) > parseFloat(total_reg);
    } else {
      return parseFloat(totalPay) <= 0 || parseFloat(total_reg) < parseFloat(totalPay/2) || parseFloat(total_cash) < parseFloat(totalPay/2) ;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const selectedOption = event.target.options[event.target.selectedIndex];
    const totalPayment = selectedOption.getAttribute('data-total-payment') - currentPackageAmount;
    setTotalPay(parseFloat(totalPayment).toFixed(2))
   
    validation.setValues({ ...validation.values, [name]: value, total_payment: totalPayment });
  };
 
  const handleChange2 = (event) => {
    const { name, value } = event.target; 
    setTotalPay(parseFloat(currentPackageAmount).toFixed(2))
   
    validation1.setValues({ ...validation1.values, [name]: value, total_payment: currentPackageAmount });
  };
 
  const columns = useMemo(
    () => [ 
      {
        header: props.t("Package"),
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              <h5 className="text-truncate font-size-14">
                <Link to={`#`} className="text-dark">$ {cellProps.row.original.amount} </Link>
              </h5> 
            </>
          );
        }
      },
      {
        header: props.t("direct reward"),
        accessorKey: "reward_referral_pal",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reward_referral_pal}%
            </>
          );
        }
      }, 
  
      {
        header: props.t("community reward"),
        accessorKey: "reward_area_pal",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reward_area_pal}%
            </>
          );
        }
      }, 
  
      {
        header: props.t("Expired"),
        accessorKey: "max_reward",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.max_reward} {props.t("times")}
            </>
          );
        }
      }, 
      {
        header: props.t("Share"),
        accessorKey: "share_pal",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.share_pal}%
            </>
          );
        }
      }, 
  
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <>
              { 
                  cellProps.row.original.is_new === true ? ( 
                   
                    cellProps.row.original.can_buy === true ? ( 
                      <button className="btn btn-primary btn-sm" onClick={() => handlePackageClick(cellProps.row.original)}>{props.t("purchase now")}</button> 
                    ) : ( 
                      <button className="btn btn-success btn-sm" disabled>{props.t("already purchase")}</button>
                 
                    )
                  ) : (
                    cellProps.row.original.can_upgrade === true ? ( 
                      cellProps.row.original.is_blackfill === 0 ? ( 
                        <button className="btn btn-warning btn-sm" onClick={() => handlePackageUpgradeClick(cellProps.row.original)}>{props.t("Upgrade")}</button>
                      ): (

                        <button className="btn btn-warning btn-sm" disabled>{props.t("Upgrade")}({props.t("Disabled")})</button>
                      )
                    ) : ( 
                      <button className="btn btn-success btn-sm" disabled>{props.t("already purchase")}</button>
                    )
                  )
              }
 
            </>
          );
        }
      },

      
    ],
    [props, isNew]
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="border-bottom">
                              <div className="d-flex align-items-center">
                                  <h5 className="mb-0 card-title flex-grow-1">{props.t("Package Lists")}</h5>
                                  <div className="flex-shrink-0">
                                      <Link to="/purchase-list"className="btn btn-primary me-1">{props.t("Purchase Records")}</Link> 
                                  </div>
                              </div>
                          </CardBody> 
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={packageData || []}
                              isGlobalFilter={false}
                              isAddButton={false}
                              isPagination={false}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>

      <Modal isOpen={modal} 
        centered
        toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
           {props.t("Purchase Package")}
        </ModalHeader>
        <ModalBody>
          
        <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation1.handleSubmit();
                return false;
              }}
            > 
            <Row>
              <Col xs={12}>   
                <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                       <label>{props.t("Package")}</label>
                       <InputGroup className="input-group-alternative mb-3 disabled">  
                         <Input 
                           value={packageTitle}
                           disabled={true} 
                         />
                       </InputGroup>
                     </FormGroup> 
                   </Col>  
                 </Row>  
                 <Row>  
                   
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                         <label>{props.t("Pay Type")}</label>
                         <select
                           name="pay_type"
                           className="form-select"
                           id="pay_type-input"
                           onChange={handleChange2}
                           onBlur={validation1.handleBlur}
                           value={validation1.values.pay_type} // Set the value attribute
                         >
                          <option value="total_reg">{props.t("total_reg")}</option> 
                          <option value="total_mix">50% {props.t("total_reg")} + 50% {props.t("total_cash")}</option> 
                         </select> 
                        {validation1.touched.pay_type && validation1.errors.pay_type ? (
                          <FormFeedback type="invalid">{validation1.errors.pay_type}</FormFeedback>
                        ) : null}
                     </FormGroup>  
                   </Col>  
                  
                  <Col lg="12"> 
                    <FormGroup className="mb-3"> 
                      <label>{props.t("Wallet Balance")}</label>
                      <div className="balance_box"> 
                        {validation1.values.pay_type === 'total_mix' ? (
                          <>
                            <div> 
                              {props.t("total_reg")}: {total_reg}
                              {parseFloat(total_reg) < parseFloat(totalPay/2) && (
                                <span className="text-red">({props.t("Balance Not enough")})</span>
                              )} 
                            </div>
                            <div>
                              {props.t("total_cash")}: {total_cash} 
                              {parseFloat(total_cash) < parseFloat(totalPay/2) && (
                                <span className="text-red">({props.t("Balance Not enough")})</span>
                              )}
                            </div>
                          </>
                        ) : ( 
                          <div>
                            {props.t("total_reg")}: {total_reg}
                              {parseFloat(total_reg) < parseFloat(totalPay) && (
                                <span className="text-red">({props.t("Balance Not enough")})</span>
                              )} 
                          </div>
                        )} 
                      </div> 
                    </FormGroup> 
                  </Col>   

                  <Col lg="12"> 
                     <FormGroup className="mb-3">
                       <label>{props.t("Total Payment")}</label>
                       <InputGroup className="input-group-alternative mb-3 disabled">  
                         <Input 
                           value={totalPay}
                           disabled={true} 
                         />
                       </InputGroup>
                     </FormGroup> 
                   </Col>  
                 </Row>  
              </Col>
            </Row>
              
            <div className="row justify-content-center"> 
              <Col className="row justify-content-center"> 
                  <button
                    className="btn btn-primary btn-block "  
                    type="submit"
                    disabled={isPurchaseDisabled()}
                    >
                    {props.t("Submit")}
                  </button> 
              </Col> 
            </div>  
          </Form>
        </ModalBody>
      </Modal>
      

      <Modal isOpen={modalUpgrade} 
        centered
        toggle={toggleUpgrade}>
        <ModalHeader toggle={toggleUpgrade} tag="h4">
           {props.t("Upgrade")}
        </ModalHeader>
        <ModalBody>
          <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            > 
            <Row>
              <Col xs={12}>  
                <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                       <label>{props.t("Current Package")}</label>
                       <InputGroup className="input-group-alternative mb-3 disabled">  
                         <Input 
                           value={currentPackage}
                           disabled={true} 
                         />
                       </InputGroup>
                     </FormGroup> 
                   </Col>  
                 </Row>  
                 <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                         <label>{props.t("New Package")}</label>
                         <select
                           name="newpackageid"
                           className="form-select"
                           id="newpackageid-input"
                           onChange={handleChange}
                           onBlur={validation.handleBlur}
                           value={validation.values.newpackageid} 
                         >
                          <option value="">{props.t("Please Select New Package")}</option>
                          
                         {packageData?.length > 0 && packageData.map((packageItem) => {
                              if (Object.values(upgrade_ids).includes(packageItem.id)) {
                                  return (
                                      <option 
                                        key={packageItem.id} 
                                        value={packageItem.id}
                                        data-total-payment={packageItem.amount}
                                        >
                                          ${packageItem.title}
                                      </option>
                                  );
                              }
                              return null;
                          })}
                         </select> 
                        {validation.touched.newpackageid && validation.errors.newpackageid ? (
                          <FormFeedback type="invalid">{validation.errors.newpackageid}</FormFeedback>
                        ) : null}
                     </FormGroup>  
                   </Col>  
                   
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                         <label>{props.t("Pay Type")}</label>
                         <select
                           name="pay_type"
                           className="form-select"
                           id="pay_type-input"
                           onChange={validation.handleChange}
                           onBlur={validation.handleBlur}
                           value={validation.values.pay_type} // Set the value attribute
                         >
                          <option value="total_reg">{props.t("total_reg")}</option> 
                          <option value="total_mix">50% {props.t("total_reg")} + 50% {props.t("total_cash")}</option> 
                         </select> 
                        {validation.touched.pay_type && validation.errors.pay_type ? (
                          <FormFeedback type="invalid">{validation.errors.pay_type}</FormFeedback>
                        ) : null}
                     </FormGroup>  
                   </Col>  
 
                   <Col lg="12"> 
                     <FormGroup className="mb-3"> 
                      <label>{props.t("Wallet Balance")}</label>
                      <div className="balance_box"> 
                      {validation.values.pay_type === 'total_mix' ? (
                        <>
                          <div> 
                            {props.t("total_reg")}: {total_reg}
                            {parseFloat(total_reg) < parseFloat(totalPay/2) && (
                              <span className="text-red">({props.t("Balance Not enough")})</span>
                            )} 
                          </div>
                          <div>
                            {props.t("total_cash")}: {total_cash} 
                            {parseFloat(total_cash) < parseFloat(totalPay/2) && (
                              <span className="text-red">({props.t("Balance Not enough")})</span>
                            )}
                          </div>
                        </>
                      ) : ( 
                        <div>
                          {props.t("total_reg")}: {total_reg}
                            {parseFloat(total_reg) < parseFloat(totalPay) && (
                              <span className="text-red">({props.t("Balance Not enough")})</span>
                            )} 
                        </div>
                      )}  
                        </div>
                     </FormGroup> 
                   </Col>   

                    <Col lg="12"> 
                      <FormGroup className="mb-3">
                        <label>{props.t("Total Payment")}</label>
                        <InputGroup className="input-group-alternative mb-3 disabled">  
                          <Input 
                            value={totalPay}
                            disabled={true} 
                          />
                        </InputGroup>
                      </FormGroup> 
                    </Col>  

                 </Row>  
              </Col>
            </Row>
              
            <div className="row justify-content-center"> 
              <Col className="row justify-content-center"> 
                  <button
                    className="btn btn-primary btn-block "  
                    type="submit"
                    disabled={isSubmitDisabled()}
                    >
                    {props.t("Submit")}
                  </button> 
              </Col> 
            </div>  
          </Form>
        </ModalBody>
      </Modal>
      
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(PackageList);

PackageList.propTypes = {
  t: PropTypes.any
};