import React, { useState, useEffect, useMemo  } from "react"; 

import {post} from '../../helpers/api_helper' 
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import classnames from "classnames"
import moment from 'moment';

import { withTranslation } from "react-i18next";

import { 
  Container, Row, Col, Card, CardBody, Nav, TabContent, TabPane, Label, NavItem, NavLink, Form, Alert, Input, InputGroup,
  FormGroup,
  FormFeedback
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Line from "./linechart"
import CountdownTimer from "./countdownTimer"

import * as Yup from "yup";
import { useFormik } from "formik";

import TableContainer from "../../components/Common/TableContainer";

import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';
  
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Market = (props) => { 

  useDefaultPropsWarningSuppression();
  
  //meta title
  document.title = props.t("market");
 
  const navigate = useNavigate();
  const [orderbookData, setOrderbookData] = useState([]);
  const [reservebookData, setReservebookData] = useState([]);
  const [shareData, setSharekData] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [marketdata, setMarketdata] = useState([]);
  const [sellData, setSellData] = useState([]);
  const [mustkyc, setMustkyc] = useState(false);
  const [canTrading, setCanTrading] = useState(false);
  const [limitUp, setLimitUp] = useState(false);
  const [canSell, setCanSell]  = useState(false);
  const [maxSell, setMaxSell]  = useState(0);
  const [minSell, setMinSell]  = useState(0);
  const [requiredSellLists, setRequiredSellLists] = useState([]);
  const [currentShare, setCurrentShare] = useState([]);
  const [inProgress, setInProgress]  = useState(false);
  const [language, setLanguage]  = useState(localStorage.getItem("I18N_LANGUAGE"));
  const [isForceSell, setIsForceSell]  = useState(0);
  const [sellCount, setSellCount]  = useState(0);
  const [total_purchase, setTotal_purchase]  = useState(0);
  const [total_insurance, setTotal_insurance]  = useState(0);
  const [sellLogs, setSellLogs]  = useState([]);
  const [forceSellQty, setForceSellQty]  = useState(0);

  useEffect(() => {
    userProfile(); 
    getMarket(); 
    orderHistory(); 
    reserveHistory(); 
    shareHistory(); 
    shareSellStatus(); 
    sellHistory(); 
  }, []); // Empty dependency array to run the effect only once
 
  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/profile", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
      } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let userdata = response.data.data;
        setUserdata(userdata);
        if(userdata.is_kyc === 0){
          setMustkyc(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMarket = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/market", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let marketData = response.data.data; 
        setTotal_purchase(marketData.total_purchase)
        setTotal_insurance(marketData.total_insurance)
        setSellLogs(marketData.sellLogs)
        setForceSellQty(marketData.forceSellQty)
        // setMarketdata(priceData.data);  
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reserveHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/reserveHistory", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data?.data){  
          let reserveData = response.data.data; 
          setReservebookData(reserveData.data); 
        } 
      }
    } catch (error) {
      console.log(error);
    }
  };

  const orderHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/orderHistory", {token: obj.token});
 
      if (response.data.code !== 1) { 
        Notify.failure(props.t(response.data.message)); 
      } else {
        let orderData = response.data.data; 
        setOrderbookData(orderData.data);  
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sellHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/sellHistory", {token: obj.token});
 
      if (response.data.code !== 1) { 
        Notify.failure(props.t(response.data.message)); 
      } else {
        let orderData = response.data.data; 
        setSellData(orderData.data);  
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shareHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/shareHistory", {token: obj.token});
 
      if (response.data.code !== 1) { 
        Notify.failure(props.t(response.data.message)); 
      } else {
        let orderData = response.data.data; 
        setSharekData(orderData.data);  
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shareSellStatus = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/sellStatus", {token: obj.token});
 
      if (response.data.code !== 1) { 
        Notify.failure(props.t(response.data.message)); 
      } else {
        let sellStatus = response.data.data;  
        setCanSell(sellStatus.canSell)  
        setMinSell(sellStatus.minSell)     
        setSellCount(sellStatus.queueSellCount)   
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goto = (path) => {
    navigate("/"+path)
  }
  
  const getBadge = (type, t, title) => {
    switch (type) {
      case "normal_reserve":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-success">
            {t("Package")}
          </span>
        );
      case "buyback_reserve":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-info">
            {t("Buyback")}
          </span>
        );
      case "split":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-danger">
            {t("Share Split")}
          </span>
        );
      case "sell":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-danger">
            {t("Share Sell")}
          </span>
        );
      case "split_action":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-warning">
            {t("Split Action")}
          </span>
        );
      case "System":
        return (
          <span className="font-size-11 bg-secondary badge badge-soft-danger">
            {t("System")}
          </span>
        );
      case "force_sell":
        return (
          <span className="font-size-11 bg-danger text-white badge">
            {t("System")}
          </span>
        );
      default:
        return null;
    }
  };

  const validationSell = useFormik({ 
    enableReinitialize: true,

    initialValues: { 
      trading_password: '',
      qty: 0
    },
    validationSchema: Yup.object().shape({
      qty: Yup.number()
      .typeError(props.t("Qty must be a number"))
      .min(minSell, props.t("Qty must be greater than or equal to %p", { p: minSell }))
      .required(props.t("Please Enter Qty")),
      trading_password: Yup.string().required(props.t("Please Enter Your Trading Password")),  
    }),
    validate: (values) => {
      const errors = {};
      if (values.qty < minSell) {
        errors.qty = parseMessage(props.t("Qty must be greater than or equal to %p"), { p: minSell });
      } 
      return errors;
    },
    onSubmit: async (values) => { 
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.token = obj.token; 
        const response = await post("/trade/sellStock", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){  
          if(response.data.data?.message){
            let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
            Notify.failure(message); 
          } else { 
            Notify.failure(props.t(response.data.message)); 
          }  

        } else {
          
          if(response.data.data?.result){
            Notify.success(props.t(response.data.data.result)); 
          } else if(response.data.data?.message){
            Notify.success(props.t(response.data.data.message)); 
          } else { 
            Notify.success(props.t(response.data.message)); 
          } 

          userProfile(); 
          getMarket();  
          shareHistory(); 
          shareSellStatus(); 
          sellHistory(); 

          validationSell.resetForm();
          setEnableBtn(false);
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });
 
  const [enableBtn, setEnableBtn]  = useState(false);
  useEffect(() => {
    if(canSell && validationSell.isValid && validationSell.values.trading_password &&  validationSell.values.qty > 0){
      setEnableBtn(true);
    }
  }, [validationSell, canSell]);
     
  const [customActiveTab, setcustomActiveTab] = useState("3"); 

  const columnsReserve = useMemo(
    () => [ 
      {
        header: props.t("Reserve Time"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.order_number}
            </>
          );
        }
      },   
      {
        header: props.t("Stock Value"),
        accessorKey: "price",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {getBadge(cellProps.row.original.type+"_reserve", props.t )}
            {cellProps.row.original.share_value}
            </>
          );
        }
      },
      {
        header: props.t("Status"),
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {props.t(cellProps.row.original.status)}
            </>
          );
        }
      },  
      
    ],
    [props]
  );

  const columnsOrder = useMemo(
    () => [ 
      {
        header: props.t("Match Time"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.order_number}
            </>
          );
        }
      },  
      {
        header: props.t("Reserve Number"),
        accessorKey: "reserve_order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reserve_order_number}
            </>
          );
        }
      },  
      {
        header: props.t("Stock Price"),
        accessorKey: "price",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.price}
            </>
          );
        }
      },
      {
        header: props.t("Qty"),
        accessorKey: "qty",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.qty}
            </>
          );
        }
      },  
      
    ],
    [props]
  );

  const columnsShare  = useMemo(
    () => [     
      {
        header: props.t("Logs"),
        accessorKey: "logs",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.logs?.length > 0 && ( 
                <>
                  {cellProps.row.original.logs.map((item, index) => (
                    <div key={index}>
                      {item.datetime} - 
                      {getBadge(item.type, props.t, item.title)}
                      {parseMessage(props.t(item.title), item.params)}
                    </div>
                  ))} 
                </>
              )}
            </>
          );
        }
      } 
    ],
    [props]
  );
 
  const columnsSell  = useMemo( 
    () => [ 
      {
        header: props.t("Sell Time"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.order_number}
            </>
          );
        }
      },   
      {
        header: props.t("Qty"),
        accessorKey: "qty",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.qty}
            {cellProps.row.original.is_force_sell === 1 && (
              <span className="font-size-11 bg-danger text-white badge ml-1">
                {props.t("is_force_sell")}
              </span>
            )}
            </>
          );
        }
      },
      {
        header: props.t("Status"),
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {props.t("sell_"+cellProps.row.original.status)}
            </>
          );
        }
      } 
    ],
    [props]
  );
 
  const SellInstruction = ({ requiredQty, requiredDate, t }) => {
    const message = parseMessage(t("please sell %required_qty of share before %required_date"), {
      required_qty: requiredQty,
      required_date: requiredDate,
    });

    return (
      <span className="font-size-11 badge-soft-danger badge bg-secondary">
        {message}
      </span>
    );
  };

  function getCellStatusClass(status) {
    switch (status) {
      case 0:
        return 'badge-soft-warning';
      case 1:
        return 'badge-soft-danger';
      case 2:
        return 'badge-soft-info';
      case 3:
        return 'badge-soft-success';
      default:
        return '';
    }
  }
  
  function getCellStatusText(props, status) {
    switch (status) {
      case 0:
        return props.t("waiting_for_open");
      case 1:
        return props.t("open_for_sell");
      case 2:
        return props.t("queue_for_sell");
      case 3:
        return props.t("sell_complete");
      default:
        return '';
    }
  }

  const [activeactionTab, setActiveactionTab] = useState("2")

  const toggleTab = tab => {
    if (toggleTab !== tab) {
      setActiveactionTab(tab)
    }
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
 
  function getLocale(language) {
    switch (language) {
      case 'cn':
        return 'zh-cn';
      case 'ko':
        return 'ko';
      case 'jp':
        return 'ja';
      default:
        return 'en';
    }
  }
  return (
    
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("market")} breadcrumbItem={props.t("market")} />
  
          <Row> 
           
            <Col sm="4" xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">{props.t("total_purchase")}</h4> 
                  <div> 
                    <h1>{total_purchase || 0}</h1> 
                  </div> 
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">{props.t("total_insurance")}</h4> 
                  <div> 
                    <h1>{total_insurance || 0}</h1> 
                  </div> 
                </CardBody>
              </Card>
            </Col>
           
            <Col sm="4" xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">{props.t("sell")}</h4> 
                  <div> 
                    <h1>{userdata?.total_share || 0}</h1>
                    {forceSellQty > 0  && (
                      <div className="share_info">  
                          <div className={`alert alert-warning  fade show`}> 
                            { parseMessage(props.t("Force sell notification, share qty to sell: %qty"), { qty: forceSellQty })}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-2"> 
                  <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationSell.handleSubmit();
                          return false;
                        }}
                      >
                        <div> 
                            {sellCount > 0 && ( 
                              <div className="share_info"> 
                                <div className={`alert alert-danger  fade show`}> 
                                    <h5>{parseMessage(props.t("There was %sellCount sell in queue"), {
                                          sellCount: sellCount,
                                        })}</h5> 
                                </div>  
                              </div>  
                            )}

                          <div>
                            <FormGroup className="mb-1">
                              <InputGroup> 
                                <Label className="input-group-text">{props.t("Qty")}</Label>
                                <Input type="number" name="qty" className="form-control"  
                                onChange={validationSell.handleChange}
                                onBlur={validationSell.handleBlur}
                                value={validationSell.values.qty || 0}
                                disabled={!canSell}
                                // min={minSell}
                                min={1} 
                                />
                              </InputGroup>
                                {validationSell.touched.qty && validationSell.errors.qty ? (
                                  <FormFeedback type="invalid">{validationSell.errors.qty}</FormFeedback>
                                ) : null} 
                            </FormGroup> 
                            <FormGroup className="mb-1">
                              <InputGroup > 
                                <Label className="input-group-text">{props.t("Trading Password")}</Label>
                                <Input type="password" name="trading_password" className="form-control"  
                                onChange={validationSell.handleChange}
                                onBlur={validationSell.handleBlur}
                                value={validationSell.values.trading_password || ""}
                                disabled={!canSell}
                                />
                              </InputGroup>
                                {validationSell.touched.trading_password && validationSell.errors.trading_password ? (
                                  <FormFeedback type="invalid">{validationSell.errors.trading_password}</FormFeedback>
                                ) : null} 
                            </FormGroup> 
                            <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                disabled={!enableBtn}
                                >
                                {props.t("Submit")}
                              </button> 
                          </div>
                        </div>
                        </Form>
                  </div>
                  
                  {inProgress && (
                    <div className="loadingBox">
                      <span>{props.t("Stock Split in progress")}</span>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col sm="4" xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">{props.t("sell queue(Top 10)")}</h4> 
                  {sellLogs?.length > 0  && (
                   <div className="sell_lists">   
                      <ul>
                        {sellLogs.map((item, index) => ( 
                          <li key={index}>
                            <span>{item.datetime}</span>: {item.username} {props.t("sell")} {item.qty} {props.t("Share")}
                          </li>  
                        ))}
                      </ul> 
                    </div>
                  )} 
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
 
                <Nav tabs className="nav-tabs-custom nav-justified mb-3">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3", })} onClick={() => { toggleCustom("3"); }}>
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-wallet me-1"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Share Records")}</span>
                      </NavLink>
                    </NavItem> 
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-list-alt"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Order Records")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }}>
                        <span className="d-block d-sm-none">
                        <i className="fas fa-print"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Reserve Records")}</span>
                      </NavLink>
                    </NavItem> 
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "4", })} onClick={() => { toggleCustom("4"); }}>
                        <span className="d-block d-sm-none">
                        <i className="fas fa-hands-helping"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Sell Records")}</span>
                      </NavLink>
                    </NavItem> 
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                  >
                    <TabPane tabId="4"> 
                        <div className="d-block d-sm-none mb-3 mt-3">
                          <h4>{props.t("Sell Records")}</h4>
                        </div>
                        <TableContainer
                            columns={columnsSell}
                            data={sellData || []}
                            isGlobalFilter={false}
                            isAddButton={false}
                            isPagination={false}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane> 
                    <TabPane tabId="3"> 
                        <div className="d-block d-sm-none mb-3 mt-3">
                          <h4>{props.t("Share Records")}</h4>
                        </div>
                        <TableContainer
                            columns={columnsShare}
                            data={shareData || []}
                            isGlobalFilter={false}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane> 
                    <TabPane tabId="1"> 
                        <div className="d-block d-sm-none mb-3 mt-3">
                          <h4>{props.t("Order Records")}</h4>
                        </div>
                        <TableContainer
                            columns={columnsOrder}
                            data={orderbookData || []}
                            isGlobalFilter={true}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane>
                    <TabPane tabId="2"> 
                        <div className="d-block d-sm-none mb-3 mt-3">
                          <h4>{props.t("Reserve Records")}</h4>
                        </div>
                        <TableContainer
                            columns={columnsReserve}
                            data={reservebookData || []}
                            isGlobalFilter={true}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane> 
                  </TabContent>

                </CardBody>
              </Card>
            </Col> 
          </Row>
        </Container>
      </div>
    </React.Fragment>
    
  );
}; 
export default withTranslation()(Market);

Market.propTypes = {
  t: PropTypes.any
};