import usFlag from "../assets/images/flags/us.jpg"
import china from "../assets/images/flags/china.jpg" 
import koFlag from "../assets/images/flags/korean.jpg" 
import jpFlag from "../assets/images/flags/japan.jpg" 

const languages = {
  cn: {
    label: "Chinese",
    flag: china,
  },
  // en: {
  //   label: "English",
  //   flag: usFlag,
  // },
  // ko: {
  //   label: "Korean",
  //   flag: koFlag,
  // },
  // jp: {
  //   label: "Japanese",
  //   flag: jpFlag,
  // },
}

export default languages
