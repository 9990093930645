import React, { useState, useEffect, useMemo  } from "react"; 

import {post} from '../../helpers/api_helper' 
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import classnames from "classnames"
 
import { withTranslation } from "react-i18next";
import { 
  Container, Row, Col, Card, CardBody, Nav, TabContent, TabPane, NavItem, NavLink
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik"; 
import TableContainer from "../../components/Common/TableContainer";

import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Activity = (props) => { 

  useDefaultPropsWarningSuppression();
  
  //meta title
  document.title = props.t("market");
 
  const navigate = useNavigate();
  const [userdata, setUserdata] = useState([]);
  const [marketdata, setMarketdata] = useState([]);
  const [mustkyc, setMustkyc] = useState(false);
  const [canTrading, setCanTrading] = useState(false);
  const [limitUp, setLimitUp] = useState(false);

  useEffect(() => {
    userProfile(); 
    activityHistory(); 
    rewardHistory();  
    dailyHistory();  
  }, []); // Empty dependency array to run the effect only once
 
  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/profile", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
      } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let userdata = response.data.data;
        setUserdata(userdata);
        if(userdata.is_kyc === 0){
          setMustkyc(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const [activityData, setActivityData] = useState([]); 
  const activityHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/activity", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let datalogs = response.data.data; 
        setActivityData(datalogs.data);  
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const [dailyData, setDailyData] = useState([]);
  const dailyHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/dailyHistory", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else { 
        if(response.data?.data){  
          let datalogs3 = response.data.data; 
          setDailyData(datalogs3.data);  
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [rewardData, setRewardData] = useState([]);
  const rewardHistory = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/rewardHistory", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data?.data){  
          let datalogs2 = response.data.data; 
          setRewardData(datalogs2.data);   
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goto = (path) => {
    navigate("/"+path)
  }
  
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const columnsReward = useMemo(
    () => [ 
      {
        header: props.t("Reward Time"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("Reward Type"),
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {props.t(cellProps.row.original.type)}
            </>
          );
        }
      },   
      {
        header: props.t("Type"),
        accessorKey: "type2",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {props.t(cellProps.row.original.type2)}
            </>
          );
        }
      },   
      {
        header: props.t("Amount"),
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              <span className={cellProps.row.original.status === 9 ? 'red' : 'green'}>
                {cellProps.row.original.amount}
              </span>
              {cellProps.row.original.status === 9 && (
                <span className="italic ">({props.t('recall')})</span>
              )}
            </>
          );
        }
      }
      
    ],
    [props]
  );

  const columnsActivity = useMemo(
    () => [ 
      {
        header: props.t("Date"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("Title"),
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {props.t(cellProps.row.original.title)}
            </>
          );
        }
      },  
      {
        header: props.t("Content"),
        accessorKey: "content",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {parseMessage(props.t(cellProps.row.original.content), cellProps.row.original.params)} 
            </>
          );
        }
      },    
      
    ],
    [props]
  );

  const columnsDaily = useMemo(
    () => [ 
      {
        header: props.t("Date"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.datetime}
            </>
          );
        }
      },
      {
        header: props.t("total_reward"),
        accessorKey: "total_reward",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.total_reward}
            </>
          );
        }
      },  
      // {
      //   header: props.t("total_sales_left"),
      //   accessorKey: "total_sales_left",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <>
      //       {cellProps.row.original.total_sales_left}
      //       </>
      //     );
      //   }
      // },    
      // {
      //   header: props.t("total_sales_right"),
      //   accessorKey: "total_sales_right",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <>
      //       {cellProps.row.original.total_sales_right}
      //       </>
      //     );
      //   }
      // },   
      {
        header: props.t("area_reward_amount"),
        accessorKey: "area_reward_amount",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
            {cellProps.row.original.area_reward_amount}
            </>
          );
        }
      },   
      
    ],
    [props]
  );

  const toggleTab = tab => {
    if (toggleTab !== tab) {
      setActiveactionTab(tab)
    }
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  
  return (
    
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody> 
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Activity Records")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Reward Records")}</span>
                      </NavLink>
                    </NavItem> 
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3", })} onClick={() => { toggleCustom("3"); }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Daily Report")}</span>
                      </NavLink>
                    </NavItem> 
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1"> 
                        <TableContainer
                            columns={columnsActivity}
                            data={activityData || []}
                            isGlobalFilter={true}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane>

                    <TabPane tabId="2"> 
                        <TableContainer
                            columns={columnsReward}
                            data={rewardData || []}
                            isGlobalFilter={true}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                          />  
                    </TabPane> 

                    <TabPane tabId="3"> 
                        <TableContainer
                            columns={columnsDaily}
                            data={dailyData || []}
                            isGlobalFilter={true}
                            isAddButton={false}
                            isPagination={true}
                            isCustomPageSize={false} 
                            SearchPlaceholder={props.t("9 records...")}
                            buttonClass="btn btn-success btn-rounded" 
                            tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                            props={props}
                          />  
                    </TabPane> 
                  </TabContent>

                </CardBody>
              </Card>
            </Col> 
          </Row>
        </Container>
      </div>
    </React.Fragment>
    
  );
}; 
export default withTranslation()(Activity);

Activity.propTypes = {
  t: PropTypes.any
};