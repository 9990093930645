import React, { useState } from "react";
import { Link , useNavigate} from "react-router-dom"; 
import PropTypes from 'prop-types'; 
import { withTranslation } from "react-i18next";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
 
import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify"; 
import TeamSunView from './team-sun-view';
  
const team = (props) => {
  //meta title
  document.title = props.t("Teams");

  const navigate = useNavigate();
 
  const [isLoading, setLoading] = useState(false)
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Teams")}</h5>
                        <div className="flex-shrink-0"> 
                        </div>
                    </div>
                </CardBody>
                <CardBody>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :  
                    <div className="team-chart-wrapper">
                    <div className="team-chart">
                      <TeamSunView/>
                    </div>
                    </div>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
 
export default withTranslation()(team);

team.propTypes = {
  t: PropTypes.any
};
