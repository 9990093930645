import React, { useState,useEffect  } from "react"; 

import {post} from '../../helpers/api_helper' 
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { Loading } from 'notiflix/build/notiflix-loading-aio';
 
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import CardUser from "./card-user";
import CardWelcome from "./card-welcome"; 

import StockChart from './StockChart';
 
import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
 
const Dashboard = (props) => { 

  useDefaultPropsWarningSuppression();

  //meta title
  document.title = props.t("Dashboard");
 
  const navigate = useNavigate();
  const [userdata, setUserdata] = useState([]);
  const [mustkyc, setMustkyc] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false); 
  
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);

  const handleModalClose = (modalNumber) => {
    switch (modalNumber) {
      case 1:
        setModal1Open(false);
        if (noticeOpen === true) {
          setModal2Open(true);
        }
        else if (mustkyc === true) {
          setModal3Open(true);
        }
        break;
      case 2:
        setModal2Open(false);
        if (mustkyc === true) {
          setModal3Open(true);
        }
        break;
      case 3:
        setModal3Open(false);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          setUserdata(userdata);
          if(userdata.is_kyc === 0){
            setMustkyc(true);
            setModal3Open(true);
          }

          if(userdata.set_withdraw_password == true){
            navigate('/profile'); 
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
 
  
  const [countdownDate, setCountdownDate] = useState(new Date('2024-06-25T00:00:00.000Z')); // Set the countdown date
  const [daysRemaining, setDaysRemaining] = useState(0); 
  const [notices, setNotices] = useState([]);
  const [firstNotice, setFirstNotice] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("I18N_LANGUAGE"));
  useEffect(() => {   
    setCurrentLanguage(localStorage.getItem("I18N_LANGUAGE")) 
    
  }, [props.t]);


  const posters = {
    en: [
      '/images/poster/stock-split-0-en.jpg',
      '/images/poster/stock-split-1-en.jpg',
      '/images/poster/stock-split-2-en.jpg',
      '/images/poster/stock-split-3-en.jpg',
      '/images/poster/stock-split-4-en.jpg'
    ],
    cn: [
      '/images/poster/stock-split-0-cn.jpg',
      '/images/poster/stock-split-1-cn.jpg',
      '/images/poster/stock-split-2-cn.jpg',
      '/images/poster/stock-split-3-cn.jpg',
      '/images/poster/stock-split-4-cn.jpg'
    ],
  };
   
  const [currentPoster, setCurrentPoster]  = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const timeDiff = countdownDate.getTime() - now.getTime();
      const days = Math.floor(timeDiff / (1000 * 3600 * 24)); // Calculate days remaining
      setDaysRemaining(days);
 
      if(currentLanguage === "cn"){
        setCurrentPoster(posters["cn"][days+1]);
      } else {
        setCurrentPoster(posters["en"][days+1]);
      }

    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [countdownDate]);

  useEffect(() => {
    notification(); 
  }, []);  
 
  const notification = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/common/notice", {token: obj.token}); 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let noticedata = response.data.data;
        setNotices(noticedata.lists)
        // const language = localStorage.getItem("I18N_LANGUAGE"); 
      
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [renderPrev, setRenderPrev] = React.useState(false);
  const [renderNext, setRenderNext] = React.useState(false);
  const [posterOpen, setPosterOpen] = useState(true); // Set isOpen to true initially 
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  useEffect(() => {
    if(notices[0]){
      setSelectedNotice(notices[0])
      setNoticeOpen(true)
    }
  }, [notices]); 
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Home")} breadcrumbItem={props.t("Home")} />
          <Row>
            <CardWelcome data={userdata} notices={notices}/> 
            <CardUser data={userdata}/>  
          </Row> 
 
        </Container>
      </div>

      
      <Modal isOpen={modal3Open} toggle={() => handleModalClose(3)}  
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal" 
        style={{ zIndex: 7 }}
      >
        <div>
          <ModalHeader className="border-bottom-0" toggle={() => { handleModalClose(3); }}></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">{props.t("Complete KYC Verification Today!")}</h4>
                <p className="text-muted font-size-14 mb-4">
                {props.t("Please complete KYC Verification to continue")}
                </p>  
                <Button onClick={() => navigate("/kyc-application")} color="primary" type="button" id="button-addon2">
                {props.t("Go Now")}
                </Button> 
              </div>
            </div>
          </div>
        </div> 
      </Modal>

      <Modal isOpen={modal2Open} toggle={() => handleModalClose(2)} style={{ zIndex: 8 }}> 
        <ModalBody>
          <div>
            <ModalHeader className="border-bottom-0" toggle={() => { handleModalClose(2); }}></ModalHeader>
          </div>
          <div className="modal-body">
          {selectedNotice && ( 
              <div
                dangerouslySetInnerHTML={{
                  __html: props.t(selectedNotice[`content_${currentLanguage}`]),
                }}
              /> 
          )}
          </div>
        </ModalBody>
      </Modal>
      
      {modal1Open && currentPoster && (
          <Lightbox 
            onCloseRequest={() =>  handleModalClose(1) } 
            mainSrc={currentPoster}
            enableZoom={false}
          /> 
        )}
    </React.Fragment>
    
  );
}; 
export default withTranslation()(Dashboard);

Dashboard.propTypes = {
  t: PropTypes.any
};