import React, { useEffect, useState, useRef } from "react";

import PropTypes from 'prop-types';
import JSONDigger from "json-digger";

import {post} from '../../helpers/api_helper' 
 
import { withTranslation } from "react-i18next";
// import OrganizationChart from "@dabeng/react-orgchart";
// import OrganizationChart from "@easonleeys/react-orgchart";
import AffiliateOrgchartNode from "./affiliate-react-orgchart-node";
import OrganizationChart from "../Org/ChartContainer";
 
import "flatpickr/dist/themes/material_blue.css";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormFeedback, Input, Form 
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Affiliate = (props) => {
  //meta title
  document.title = props.t("Affiliate");

  const orgchart = useRef();
  const [ members, setMembers] = useState([]);
  const [ username, setUsername] = useState();
  let transformedData = [];

  // Update data when initialData prop changes
  useEffect(() => {
    affiliateList();   
  }, []);
 
  const affiliateList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/user/affiliate", {token: obj.token});
      setUsername("")
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let data = response.data.data;
 
        const arrayOfObjects = Object.keys(data).map(key => {
          const obj = data[key];
          obj.key = key; // Add the key as a property to the object
          return obj;
        });

        setMembers(arrayOfObjects);  
        
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      username: username, 
    }, 
    onSubmit: async (values) => { 
      try {  
        setMembers([])

        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.token = obj.token; 
        values.type = "member"; 
        const response = await post("/user/affiliate", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(response.data.message); 
        } else {  
          let data = response.data.data;
           
          const arrayOfObjects = Object.keys(data).map(key => {
            const obj = data[key];
            obj.key = key; // Add the key as a property to the object
            return obj;
          }); 
          setMembers(arrayOfObjects);  
          
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });

  useEffect(() => {
    if (validation.values.username === '') {
      affiliateList();
    }
  }, [validation.values.username]);
   
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [newNodes, setNewNodes] = useState([]);

  const readSelectedNode = nodeData => { 
    setSelectedNodes([]);
    setSelectedNodes(nodeData); 
  };

  const clearSelectedNode = () => {
    setSelectedNodes([]);
  };
 
  const showChildNodes = async () => { 
    const obj = JSON.parse(localStorage.getItem("authUser"));
    let values = {};
    values.token = obj.token; 
    values.username = selectedNodes.username; 
    values.type = "downline"; 
    const response = await post("/user/affiliate", values); 
    
    if (response.data.code !== 1) {
      Notify.failure(props.t(response.data.message)); 
    } else {
      let data = response.data.data;
      const arrayOfObjects = Object.keys(data).map(key => {
        const obj = data[key];
        obj.key = key; // Add the key as a property to the object
        return obj;
      });
      selectedNodes["children"] = arrayOfObjects;
      setNewNodes(prevNewNodes => [...prevNewNodes, selectedNodes]);  
      // orgchart.current.expandAllNodes();
      // console.log(orgchart)
    }


  };

  useEffect(() => { 
    if(selectedNodes.totalChild > 0){

      if(selectedNodes !== undefined){
        if(selectedNodes.children?.length == 0){
          showChildNodes()
        }
      }
    }
  }, [selectedNodes]);

  return (
    <div className="team-wrapper">
      <Container fluid>
        <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Affiliate")}</h5>
                        <div className="flex-shrink-0"> 
                        </div>
                    </div>
                </CardBody> 
                <CardBody>               
                  <Form
                  className="row row-cols-lg-auto g-3 align-items-center" 
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >  
                    <Col xs={12}>
                    <label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">{props.t("username")}</label> 
                      <Input
                        name="username"
                        type="text"
                        placeholder={props.t("Enter Username")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username && validation.errors.username ? true : false
                        }
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                      ) : null}
                    </Col> 
                    <Col xs={12}>
                      <button type="submit" className="btn btn-primary w-md">{props.t("Search")}</button>
                      <span className="btn btn-secondary ml-1 w-md"  onClick={() => validation.setFieldValue("username", "")}>{props.t("Reset")}</span>
                    </Col>
                  </Form>
  
                  {members?.length > 0 && (  
                    members.map((item, index) => ( 
                      <OrganizationChart
                      ref={orgchart}
                      key={index}
                      datasource={item}
                      chartClass="myChart"
                      NodeTemplate={AffiliateOrgchartNode}
                      pan={true} 
                      collapsible={true} 
                      onClickNode={readSelectedNode}
                      onClickChart={clearSelectedNode}
                      />
                    ))
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
      </Container>
    </div>
  ); 
};
 
export default withTranslation()(Affiliate);

Affiliate.propTypes = {
  t: PropTypes.any
};
