import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {

  const pagetitle = process.env.REACT_APP_TITLE;

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © {pagetitle}.</Col>
            <Col md={6}> 
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
