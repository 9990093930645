import React from "react";
import { Navigate } from "react-router-dom";
  
// Profile
import UserProfile from "../pages/Authentication/user-profile";
 
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import PasswordReset1 from "../pages/Authentication/password-reset-1"; 
 
// Dashboard
import Dashboard from "../pages/Dashboard/index";  
import Market from "../pages/Dashboard/market";  

//Pages 
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
 
import PackageList from "../pages/Member/packages-list";
import DepositList from "../pages/Member/deposit-list";
import WithdrawalList from "../pages/Member/withdrawal-list";
import ReserveList from "../pages/Member/reserve-list";
import SellList from "../pages/Member/sell-list";
import PurchaseList from "../pages/Member/purchase-list";
import Affiliate from "../pages/Member/affiliate";
import Team from "../pages/Member/team";
import Invite from "../pages/Member/invite"; 
import DepositForm from "../pages/Member/deposit-form";
import Withdrawal from "../pages/Member/withdrawal";
import KYCApplication from "../pages/Member/kyc-application";
import Transfer from "../pages/Member/transfer";
import TransferCP from "../pages/Member/transferCP";
import TransferList from "../pages/Member/transfer-list";
import Sell from "../pages/Member/sell";
import Activity from "../pages/Member/activity";
import Saving from "../pages/Member/saving";
import SavingList from "../pages/Member/saving-list";
import SavingReward from "../pages/Member/saving-reward";

const authProtectedRoutes = [
   
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/market", component: <Market /> },
  { path: "/package-list", component: <PackageList /> },
  { path: "/deposit-list", component: <DepositList /> },
  { path: "/withdrawal-list", component: <WithdrawalList /> },
  { path: "/reserve-list", component: <ReserveList /> },
  { path: "/sell-list", component: <SellList /> },
  { path: "/purchase-list", component: <PurchaseList /> },
  { path: "/affiliate", component: <Affiliate /> },
  { path: "/team", component: <Team /> },
  { path: "/invite", component: <Invite /> }, 
  { path: "/deposit", component: <DepositForm /> },
  { path: "/withdrawal", component: <Withdrawal /> },
  { path: "/kyc-application", component: <KYCApplication /> },
  { path: "/transfer", component: <Transfer /> },
  { path: "/transfer-cp", component: <TransferCP /> },
  { path: "/transfer-list", component: <TransferList /> },
  { path: "/sell", component: <Sell /> },
  { path: "/saving", component: <Saving /> },
  { path: "/saving-list", component: <SavingList /> },
  { path: "/saving-reward", component: <SavingReward /> },

  { path: "/activity", component: <Activity /> },
 
  // //profile
  { path: "/profile", component: <UserProfile /> },
 
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  // Catch-all route for undefined paths
  { path: "*", component: <Pages404 /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register/:inviteCode?", component: <Register /> },
  { path: "/reset-password/:email/:token", component: <PasswordReset1 /> }, 
 
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> }, 

  { path: "*", component: <Pages404 /> },
];

export { authProtectedRoutes, publicRoutes };
