import React, { useEffect, useState } from "react" 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";

//Import Image
import features from "../../assets/images/crypto/features-img/img-1.png"

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import {post} from '../../helpers/api_helper' 

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

const CardWelcome =  (props) => {
  
  const { data, notices } = props;
   
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("I18N_LANGUAGE"));

  useEffect(() => {   
    setCurrentLanguage(localStorage.getItem("I18N_LANGUAGE")) 
  }, [props.t]);

  
  useEffect(() => { 
  }, [notices]); 

  const pagetitle = process.env.REACT_APP_TITLE;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const handleModalOpen = (notice) => {
    setSelectedNotice(notice);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  }; 
 


  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <div>
              <Row>
                <Col lg="9" sm="8">
                  <div className="p-4">
                    <h5 className="text-primary">{props.t("Welcome to")} {pagetitle}</h5>
                    <p>{props.t("Announcement Board")}</p>
 
                    {notices?.length > 0? (
                      notices.map((item, index) => (
                        <div className="text-muted" key={index}  onClick={() => handleModalOpen(item)}>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                            {item[`title_${currentLanguage}`] || item.title}
                          </p>
                        </div>
                      ))
                    ) : null}
                  </div>
                </Col>
                <Col lg="3" sm="4" className="align-self-center">
                  <div>
                    <img src={features} alt="" className="img-fluid d-block" />
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
 
      <Modal isOpen={modalOpen} toggle={handleModalClose} 
      style={{ zIndex: 8 }}> 
      <ModalBody>
          <div>
            <ModalHeader className="border-bottom-0" toggle={handleModalClose}></ModalHeader>
          </div>
        {selectedNotice && ( 
            <div
              dangerouslySetInnerHTML={{
                __html: props.t(selectedNotice[`content_${currentLanguage}`]),
              }}
            /> 
        )}
      </ModalBody>
    </Modal>

    </React.Fragment>
  )
}

export default withTranslation()(CardWelcome);

CardWelcome.propTypes = {
  t: PropTypes.any
};  
