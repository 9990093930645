import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import {encryptData, decryptData, genSign, logs} from './Utils'  
import { Loading } from 'notiflix/build/notiflix-loading-aio';

//pass new generated access token here
const token = accessToken;

//apply base url for axios 
const API_URL = process.env.REACT_APP_API_URL; 
 
const baseUrl = parseDomain();

function parseDomain(){ 
  
    const domainMappings = [
      "https://eq7m2l9w5sck31zb.greatverse.co",
      "https://tsm20n1ui6bcy8h7.greatverse.co",
      "https://o4eg3lxuz0phs6ty.greatverse.co",
      "https://gh5rzvmk9s1i8f4o.greatverse.co"
    ];

    let myurl = null; 
    if(process.env.REACT_APP_ENV === "dev"){ 
      myurl = process.env.REACT_APP_API_URL; 
    }else{
      const randomIndex = Math.floor(Math.random() * domainMappings.length);
      myurl = domainMappings[randomIndex]+"/api";
    }
     
    return myurl; 
}

const axiosApi = axios.create({
  baseURL: baseUrl,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
 
export async function get(url, data, config = {}) {
  let postData = data;
  let suffix = Math.floor(100000 + Math.random() * 900000);
  if(process.env.REACT_APP_ENV === 'dev')
  {
      suffix = 123456;
  }
     
  const i18nextLng = localStorage.getItem("i18nextLng");  
  config.headers['lang'] = i18nextLng || "en";
  config.headers['imei'] = suffix;
  config.headers['suffix'] = suffix;

  if (process.env.REACT_APP_LOCK === "true") {
    postData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['Content-Type'] = 'application/json'; // Update Content-Type for encrypted data
  }
  
  return axiosApi
    .get(url, postData, { ...config })
    .then((response) => handleResponse(response));
}

export async function post(url, data, config = {}) {
  let postData = data;
  
  // Check if config has headers defined
  if (!config.headers) {
    config.headers = {};
  } 
  let suffix = Math.floor(100000 + Math.random() * 900000);
  if(process.env.REACT_APP_ENV === 'dev')
  {
      suffix = 123456;
  }

  if (process.env.REACT_APP_LOCK === "true") {
    
    data.timestamp = Math.round(Date.now()/1000) 
    data.encode_sign = genSign(data); 

    postData = {
      "post-data": encryptData(data, suffix)
    };
  }
  if(data.token !== undefined)
  { 
    // axiosApi.defaults.headers.common["token"] = data.token;
  } 
  const i18nextLng = localStorage.getItem("i18nextLng");  
  config.headers['lang'] = i18nextLng || "en";
  config.headers['imei'] = suffix;
  config.headers['suffix'] = suffix;
  config.headers['Content-Type'] = 'application/json';
  // console.log("config")
  // console.log(config)
  
  Loading.pulse({
    backgroundColor: 'rgba(0,0,0,0.6)',
  });
  return axiosApi
    .post(url, postData, { ...config })
    .then((response) => handleResponse(response));
}

export async function put(url, data, config = {}) {
  let putData = data;
  if (process.env.REACT_APP_LOCK === "true") {
    let suffix = Math.floor(100000 + Math.random() * 900000);
    if(process.env.REACT_APP_ENV === 'dev')
    {
        suffix = 123456;
    }
    putData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['lang'] = "en";
    config.headers['imei'] = suffix;
    config.headers['suffix'] = suffix;
    config.headers['Content-Type'] = 'application/json';
  }

  return axiosApi
    .put(url, putData, { ...config })
    .then((response) => handleResponse(response));
}


export async function del(url, data, config = {}) {
  let postData = data;
  if (process.env.REACT_APP_LOCK === "true") {
    let suffix = Math.floor(100000 + Math.random() * 900000);
    if(process.env.REACT_APP_ENV === 'dev')
    {
        suffix = 123456;
    }
    postData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['lang'] = "en";
    config.headers['imei'] = suffix;
    config.headers['suffix'] = suffix;
    config.headers['Content-Type'] = 'application/json';
  }
  
  return axiosApi
    .delete(url, postData, { ...config })
    .then((response) => handleResponse(response));
} 

function handleResponse(response) {
  let responseData = response.data; 
  if (process.env.REACT_APP_LOCK === "true") {
    let decryptedData = decryptData(responseData.data, responseData.suffix);
    if (decryptedData !== null) {
      responseData = decryptedData; 
      responseData = JSON.parse(responseData); 
    } else {
      console.log("Handle decryption error")
    }
  }
  Loading.remove(1000);
  return responseData;
}