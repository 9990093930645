import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback
} from "reactstrap";


import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { post } from '../../helpers/api_helper' 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
 
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
 
const TransferCP = props => {

  //meta title
  document.title=props.t("Tranfer CP");
 
  const navigate = useNavigate();   
  const [canSubmit, setCanSubmit] = useState(false);  
  const [wallet_type] = useState("total_cash");

  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          
          if(userdata.set_withdraw_password == true){
            navigate('/profile'); 
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  
  }, []); // Empty dependency array to run the effect only once
 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      amount: '',
      trading_password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Username")),  
      amount: Yup.number()
      .typeError(props.t("Amount must be a number"))
      .min(0, props.t("Amount must be greater than or equal to 0"))
      .required(props.t("Please Enter Amount")), 
      trading_password: Yup.string().required(props.t("Please Enter Your Trading Password")),  
    }), 
    onSubmit: async (values) => { 
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser")); 
        values.token = obj.token;
        values.wallet_type = wallet_type;
        const response = await post("/user/transfer", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else { 
          let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
          Notify.success(message); 
          
          navigate('/transfer-list'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.failure(error.data.message); 
      }
    }, 
  });
 
  useEffect(() => { 
    if (
      validation.values.username &&
      validation.values.amount &&
      validation.values.trading_password && 
      validation.values.amount > 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    } 
  }, [validation.values]);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        
          <Row className="justify-content-center">
            <Col>
              <Card> 
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Tranfer RW")}</h5>
                        <div className="flex-shrink-0">
                            <Link to="/transfer-list"className="btn btn-primary me-1">{props.t("Transfer Records")}</Link> 
                        </div>
                    </div>
                </CardBody> 
                <CardBody> 
                    <div className="justify-content-start"> 
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                          <div id="kyc-verify-wizard" className="wizard clearfix">  
                            <Row> 
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="username-input"
                                    className="form-label"
                                  >
                                    {props.t("wallet_type")}
                                  </Label>
                                  <Input 
                                    className="form-control"
                                    type="text"  
                                    value={props.t(wallet_type)}
                                    readonly
                                  /> 
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="username-input"
                                    className="form-label"
                                  >
                                    {props.t("Username")}
                                  </Label>
                                  <Input
                                    name="username"
                                    className="form-control"
                                    type="text" 
                                    id="username-input"
                                    placeholder={props.t("Enter Username")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                  />
                                  {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="amount-input"
                                    className="form-label"
                                  >
                                    {props.t("Amount")}
                                  </Label>
                                  <Input
                                    name="amount"
                                    type="number"
                                    className="form-control"
                                    id="amount-input"
                                    placeholder={props.t("Enter Amount")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.amount || ""}
                                  />
                                  {validation.touched.amount && validation.errors.amount ? (
                                    <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row> 
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="trading_password-input"
                                    className="form-label"
                                  >
                                    {props.t("Trading Password")}
                                  </Label>
                                  <Input
                                    name="trading_password"
                                    type="password"
                                    className="form-control"
                                    id="trading_password-input"
                                    placeholder={props.t("Enter Trading Password")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.trading_password || ""}
                                  />
                                  {validation.touched.trading_password && validation.errors.trading_password ? (
                                    <FormFeedback type="invalid">{validation.errors.trading_password}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col> 
                            </Row> 
                            <div className="row justify-content-center"> 
                              <Col className="row justify-content-center"> 
                                <button
                                  className="btn btn-primary btn-block "
                                  type="submit"
                                  disabled={!canSubmit}
                                  >
                                  {props.t("Submit")}
                                </button> 
                              </Col> 
                            </div>  
                          </div>
                      </Form>
                    </div> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}; 
export default withTranslation()(TransferCP);

TransferCP.propTypes = {
  t: PropTypes.any
};