import PropTypes from "prop-types";
import React, { useState, useEffect  } from "react"; 

import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
 
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect"; 
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {post} from '../../helpers/api_helper'  
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
  
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const ForgetPasswordPage = props => {

  const navigate = useNavigate();
  //meta title 
  document.title = props.t("Forget Password")+"";

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [isSend, setIsSend] = useState(false);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    
    if(obj?.token){
      navigate('/profile'); 
    }
    
  }, []); // Empty dependency array to run the effect only once

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please Enter Your Email")),
      username: Yup.string().required(props.t("Please Enter Your Username")),
    }),
    onSubmit: (values) => {  
        setEmail(values.email);
        setUsername(values.username); 
        sendRequest(values.email, values.username)
    },
  });

  const sendRequest = async (email, username) => { 
    let formData = {
      email: email,
      username: username
    }; 

    try {
      const response = await post("/user/forgotPassword", formData);  
      if(response.data.code !== 1){ 
        if(response.data.data?.message){
          let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
          Notify.failure(message); 
        } else { 
          Notify.failure(props.t(response.data.message)); 
        }
      } else { 
        Notify.success(props.t("Password Reset Request Submitted Successfully. Please check your email for further instructions.")); 
        setIsSend(true); 
      }
    } catch (error) {  
      console.log(error);
      Notify.error(props.t("An error occurred while sending the request.")); 
    }
  }

  const handleResend = () => {
    sendRequest(email, username);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Forget Password")}</h5>
                        <p>{props.t("Reset Password")}</p> 
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> 
                      </div>
                    </Link>
                    <div className="nav-lang">
                      <LanguageDropdown />
                    </div>
                  </div> 
                  <div className="p-2">  
                    {!isSend ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">{props.t("Email")}</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder={props.t("Enter email")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">{props.t("Username")}</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder={props.t("Enter username")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              {props.t("Submit")}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <div>
                        <h4>{props.t("Verify your email")}</h4>
                        <p>
                          {props.t("We have sent you verification email")}{" "}
                          <span className="fw-semibold">
                            {email}
                          </span>
                          , {props.t("Please check it")}
                        </p> 

                        <p>
                          {props.t("Didn't receive an email ?")}{" "}
                          <a href="#" onClick={handleResend} className="fw-medium text-primary">
                            {" "}
                            {props.t("Resend")}{" "}
                          </a>{" "}
                        </p>
                         
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                
                <p>
                {props.t("Go back to")}{" "} <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    {props.t("Login")}
                  </Link>{" "}
                </p> 
                <p>
                  © {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any
};
 
export default withTranslation()(withRouter(ForgetPasswordPage));
 