import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link , useNavigate} from "react-router-dom";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import {post} from '../../helpers/api_helper'  

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

const SavingReward = (props) => {
  useDefaultPropsWarningSuppression();
  //meta title
  document.title = props.t("Saving Reward");

  const navigate = useNavigate();
   
  const [isLoading, setLoading] = useState(true)
  const [modal, setModal] = useState(false);  
  const [savingRewardData, setSavingRewardData] = useState();  
  const [total_saving, setTotal_saving] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [packageTitle, setPackageTitle] = useState(""); 
  const [packageQty, setPackageQty] = useState(""); 
  const [activatedButton, setActivatedButton] = useState(true); 

  useEffect(() => { 
    rewardList();  
    userProfile();
  }, []);  
 
  const rewardList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/savingRewardList", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
    } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data?.data){  
          let listdata = response.data.data.lists;  
          setSavingRewardData(listdata); 
        } 
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/profile", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
      } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let userdata = response.data.data; 
        setTotal_saving(userdata.total_saving);
   
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const toggle = () => {
    if (modal) {
      setModal(false);
      setPackageId(null);
      setPackageTitle(null); 
    } else {
      setModal(true);
      setCurrentPackageAmount(0);
      setCurrentPackageId(0);
      setTotalPay(0);
    }
  };
  
  const handlePackageClick = useCallback((arg) => {  
    setPackageId(arg.id); 
    setPackageTitle(arg.title);  
    setPackageQty(arg.qty);  
    setModal(true); 
  }, [toggle]);
 
  useEffect(() => {   
    if(parseFloat(total_saving) < parseFloat(packageQty)){ 
      setActivatedButton(false);
    } else {
      setActivatedButton(true);
    }
  }, [total_saving, packageQty, toggle]);  
 
  const handlePurchasePackage = async () => {
    try {  
        const obj = JSON.parse(localStorage.getItem("authUser")); 
        let values = {
          id: packageId,
          token: obj.token,
        };  
        const response = await post("/trade/activateSavingReward", values); 

        if (response.data.code === 3002) {
            navigate('/logout'); 
        } 

        if (response.data.code !== 1) { 
          if(response.data.data?.message){
            let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
            Notify.failure(message); 
          } else { 
            Notify.failure(props.t(response.data.message)); 
          } 
        } else { 
          
          if(response.data.data?.result){
            Notify.success(props.t(response.data.data.result)); 
          } else { 
            Notify.success(props.t(response.data.message)); 
          } 

          setModal(false);
          rewardList();  
          userProfile();
        }
    } catch (error) {  
        console.log(error);
        // Notify.failure(error.data.message); 
    }
  }

  const columns = useMemo(
    () => [ 
      {
        header: props.t("Saving Reward"),
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              <h5 className="text-truncate font-size-14">
                <Link to={`#`} className="text-dark">{props.t(cellProps.row.original.title)} </Link>
              </h5> 
            </>
          );
        }
      },
      {
        header: props.t("Saving Qty"),
        accessorKey: "qty",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.qty}
            </>
          );
        }
      }, 
    
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <> 
              { 
                  cellProps.row.original.can_activated === true ? ( 
                    <button className="btn btn-primary btn-sm" onClick={() => handlePackageClick(cellProps.row.original)}>{props.t("activated now")}</button> 
                  ) : (
                    <button className="btn btn-success btn-sm" disabled>{props.t("activated")}</button>
                  )
              }
 
            </>
          );
        }
      },

      
    ],
    [props]
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="border-bottom">
                              <div className="d-flex align-items-center">
                                  <h5 className="mb-0 card-title flex-grow-1">{props.t("Saving Reward")}</h5>
                                  <div className="flex-shrink-0"> 
                                  </div>
                              </div>
                          </CardBody> 
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={savingRewardData || []}
                              isGlobalFilter={false}
                              isAddButton={false}
                              isPagination={false}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>


      <Modal isOpen={modal} 
        centered
        toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
           {props.t("Saving Reward")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12}> 
                <div className="mb-3">
                  <p>{parseMessage(props.t("Are you sure you want to destroy %qty stock to activated '%reward_name'?"), {
                    "qty": packageQty,
                    "reward_name": props.t(packageTitle),
                  })} </p> 
                </div> 
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  {activatedButton === true ? (
                    <> 
                      <Button color="success" 
                        className="mr-1"
                        onClick={handlePurchasePackage}
                      >
                        {props.t("Confirm")}
                      </Button>
                      <Button color="danger"  
                        onClick={toggle}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </>
                  ): (
                    <div className="text-red">{props.t("total_saving not enought to activated this rewards")}</div>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      

      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(SavingReward);

SavingReward.propTypes = {
  t: PropTypes.any
};